import React from "react"
import Layout from "../components/global/layout"
import GetInTouchToday from '../components/global/GetInTouchToday';
import SEO from "../components/global/seo"
import GenericKbsBody from "../components/templates/genericKbsBody";

const kbs = () => (
    <Layout>
        <SEO title="KBS" keywords={[`KBS`, 'KnowledgeBase system', `Information`, `Training`]}
        description="Our Knowledgebase System to help you learn more about our products and services"
        />
        <GenericKbsBody/>
        <GetInTouchToday />
    </Layout>
);

export default kbs